<template>
  <b-card no-body>
    <validation-observer v-slot="{ handleSubmit }" ref="VForm">
      <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD('VForm', $refs))">
        <b-card-header>
          <b-row>
            <b-col lg="8">
              <h5 class="card-title">{{ $parent.isAdd?'Add':'Edit' }} {{ $parent.pageTitle }}</h5>
            </b-col>
          </b-row>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col lg="8">
              <b-row>
                <b-col lg="6">
                  <b-form-group>
                    <label>Prospect Category <span class="text-danger mr5">*</span></label>
                    <b-form-input v-model="row.mpct_name" placeholder="e.g Lemari">
                    </b-form-input>
                    <VValidate name="Prospect Category" v-model="row.mpct_name" :rules="mrValidation.mpct_name" />
                  </b-form-group>
                </b-col>
                <div class="w-100"></div>
                <b-col lg="6">
                  <b-form-group class="">
                    <label>Status<span class="text-danger mr5">*</span></label>
                    <b-form-radio-group :options="Config.mr.StatusOptions" v-model="row[$parent.statusKey]" />
                    <VValidate name="Status" v-model="row.mpct_is_active" :rules="mrValidation.mpct_status" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card-body>
        <b-card-footer>
          <b-row>
            <b-col lg="12" class="text-right">
              <button @click="$router.back()" type="button" class="btn btn-rounded btn-default mr-3">Cancel</button>
              <b-button type="submit" variant="info" class="btn-rounded">Submit</b-button>
            </b-col>
          </b-row>
        </b-card-footer>
      </b-form>
    </validation-observer>
  </b-card>
</template>
<script>
export default {
  props: {
    row: Object,
    mrValidation: Object,
    Config: Object,
  },
  data(){
    return {
    }
  },
  methods: {
    doSubmitForm() {
      this.$emit('click:doSubmit', 'VForm', this.$refs)
    },
  }
}
</script>
