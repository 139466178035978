<template>
  <b-container fluid>
    <bo-page-title />
  
    <b-card v-if="isList" no-body>
      <b-card-header>
        <b-row>
          <b-col lg="5">
            <h5 class="card-title">{{ pageTitle }}</h5>
          </b-col>
          <b-col lg="3">
            <b-form-group>
              <v-select placeholer="Select Status" label="label" :reduce="v => v.value" @input="doFilter()" v-model="filter.status" :options="statusFilters"></v-select>
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <SearchInput :value.sync="filter.search" @search="doFilter" />
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body class="p-0">
        <b-table
          :fields="fields"
          :items="dataList||[]"
          :primary-key="idKey"
          :busy="!dataList"
          responsive
          show-empty
          striped
        >
          <template #empty>
            <div class="text-center">
              <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
              <h4 align="center"><span v-if="Object.keys($route.query).length">No search results found</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
            </div>
          </template>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle mr-3 text-info"></b-spinner>
              <strong class="text-info">Loading...</strong>
            </div>
          </template>
          <template #cell(number)="v">
            {{(data.per_page*(data.current_page-1))+v.index+1}}
          </template>
          <template
            #cell(mpct_is_active) = "data"
          >
            <b-badge
              v-if="data.value=='Y'"
              variant="success"
            >Active</b-badge>
            <b-badge
              v-else
              variant="danger"
            >Inactive</b-badge>
          </template>
          <template
            #cell(action)="data"
          >
            <router-link
              v-if="moduleRole('edit')"
              v-b-tooltip.hover
              title="Edit"
              class="icon_action"
              :to="{name: $route.name, params: {pageSlug: data.item[idKey]}}"
            >
              <i class="ti-marker-alt"></i>
            </router-link>
            <a
              v-if="moduleRole('changeStatus')"
              v-b-tooltip.hover
              title="Change Status"
              class="icon_action"
              @click="doChangeStatus(data.index, data.item)"
              style="cursor:pointer;"
            >
              <i class="ti-settings"></i>
            </a>
            <a
              v-if="moduleRole('delete')"
              v-b-tooltip.hover
              title="Delete"
              class="icon_action"
              @click="doDelete(data.index, data.item)"
              style="cursor:pointer;"
            >
              <i class="ti-trash"></i>
            </a>
  
            <span v-if="!(moduleRole('delete') || moduleRole('changeStatus') || moduleRole('edit'))">-</span>
          </template>
        </b-table>
      </b-card-body>
      <b-card-footer>
        <template v-if="(dataList||[]).length&&data.total>10">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <label for="perPage" class="mb-0 mr-2">Show</label>
                <b-form-select
                  size="sm"
                  id="perPage"
                  class="w-auto mx-50"
                  v-model="perPage"
                  :options="Config.dataShownOptions"
                />
              <label for="perPage" class="mb-0 ml-2">items per page</label>
            </div>
            <b-pagination
              class="mb-0"
              v-model="pageNo"
              :per-page="data.per_page"
              :total-rows="data.total"
            />
          </div>
        </template>
      </b-card-footer>
    </b-card>
  
    <template v-else>
      <Form :row.sync="row" :Config="Config" :mrValidation="mrValidation"/>
    </template>
    <div class="media-overlay" :class="showImage && detailImg ? 'show' : ''" @click.stop="showImage = false">
      <div class="media-content" @click.stop>
        <div class="media-dialog parent-wrapper">
          <b-img :src="uploader(detailImg)" />
          <b-button size="sm" @click.stop="showImage = false" class="close-button btn" variant="danger"><i class="fas fa-times"></i></b-button>
        </div>
      </div>
    </div>
  </b-container>
  </template>
  <script>
  import GlobalVue from '@/libs/Global.vue'
  import Form from './Form.vue'
  
  export default {
    extends: GlobalVue,
    components:{Form},
    data() {
      return {
        idKey:'mpct_id',
        statusKey:'mpct_is_active',
        mrValidation: {},
        showImage: false,
        detailImg: '',
        fields: [
          {
            key: 'number', label: '#', 
          },
          {
            key: 'mpct_name', label: 'Prospect Category',
          },
          {
            key: 'mpct_is_active', label: 'Status',
          },
          {
            key: 'action', 
          },
        ],
      }
    },
    mounted(){
      this.apiGet()
      this.filter.status = this.$route.query.status || ''
    },
    watch:{
      $route(){
        this.apiGet()
      },
      'filter'(){
        if('status' in this.filter == false){
          this.$set(this.filter, 'status', '')
        }
      }
    }
  }
  </script>